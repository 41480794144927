import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { Box, Button, Callout, Card, Heading, Text } from 'core/components';
import { getPortalVersion } from 'core/util';
import KentikLogo from 'app/components/KentikLogo';
import PoweredByKentik from 'app/components/PoweredByKentik';
import { FiInfo } from 'react-icons/fi';
import LoginModel from './LoginModel';
import LoginForm from './LoginForm';

@inject('$app', '$auth')
@withRouter
@observer
export class LoginEntry extends Component {
  emptyUser = new LoginModel();

  constructor(props) {
    super(props);

    const { $app, $auth, history, location } = props;

    // If already authenticated, redirect to /
    if ($auth.authenticated) {
      history.replace('/');
      return;
    }

    $app.setPageTitle('Login');

    if (this.ssoCompanyIdentifier && location.search.includes('autologin=true')) {
      history.replace(`/sso/${this.ssoCompanyIdentifier}`);
    }
  }

  get ssoCompanyIdentifier() {
    const { match } = this.props;
    return match.params.companyIdentifier;
  }

  handleSubmit = async (form, credentials) => {
    const { $auth, history, location } = this.props;

    // Make call to authenticate user.
    return $auth.authenticate(credentials).then((result) => {
      if (result.success) {
        const pathname = $auth.isSynOnboarding ? '/v4/synthetics/tests/' : get(location, 'state.from.pathname', '/');
        const search = get(location, 'state.from.search', '');

        // If the loaded version is out of date, let's just force a reload now
        getPortalVersion().then(({ hasOutdatedBundle }) => {
          if (hasOutdatedBundle) {
            window.location = `${pathname}${search}`;
          } else {
            history.replace(`${pathname}${search}`);
          }
        });
      } else if (result.isAcl) {
        form.invalidate('Login was blocked due to a configured ACL.');
      } else {
        form.invalidate('Your email and password combination were incorrect.');
      }
    });
  };

  render() {
    const { $app, $auth } = this.props;
    const { subtenancy } = $auth.openConfig;

    return (
      <>
        {subtenancy && subtenancy.config && subtenancy.config.icon && (
          <Box textAlign="center" p={3}>
            <img
              alt={subtenancy.subdomain}
              src={`/api/ui/icons/${subtenancy.company_id}`}
              style={{ width: 180, height: 'auto' }}
            />
          </Box>
        )}
        <Card maxWidth={375} minHeight={340} mx="auto" p={3} elevation={4}>
          {(!subtenancy || !subtenancy.config || !subtenancy.config.icon) && (
            <Box textAlign="center" mb={4}>
              <a href={window.location.origin}>
                <KentikLogo blackText={!$app.darkThemeEnabled} style={{ width: 200, height: 30 }} />
              </a>
            </Box>
          )}

          {!this.ssoCompanyIdentifier && (
            <Box>
              {subtenancy && (
                <Heading level={4}>{get(subtenancy, 'config.branding.appName') || 'Network Visibility Portal'}</Heading>
              )}
              <LoginForm
                authenticating={$auth.authenticating}
                authenticated={$auth.authenticated}
                handleSubmit={this.handleSubmit}
                model={this.emptyUser}
              />
            </Box>
          )}
          {this.ssoCompanyIdentifier && (
            <Fragment>
              <Button
                large
                intent="primary"
                text="Login"
                fill
                loading={$auth.authenticated || $auth.authenticating}
                onClick={() => (window.location = `/sso/${this.ssoCompanyIdentifier}`)}
              />
              {$auth.openConfig && $auth.openConfig.baseUrl && (
                <>
                  <Callout title="Your login page URL is:" mt={2} p={2}>
                    <Text as={Box} monospace ellipsis>
                      {window.location.href}
                    </Text>
                  </Callout>
                  <Callout icon={FiInfo} intent="primary" title="To skip this page next time..." mb={2} mt={2}>
                    Once successful Single Sign-on has been verified, this page can be skipped by appending{' '}
                    <code>?autologin=true</code> to the end of the page&apos;s URL.
                  </Callout>
                </>
              )}
              {$auth.ssoErrorMsg && (
                <Callout icon="error" title="Login Failed" my={2}>
                  {$auth.ssoErrorMsg}
                </Callout>
              )}
            </Fragment>
          )}
          {!subtenancy && (
            <Fragment>
              <Callout title="Trouble Logging In?" mt={2} p={2} pl={2}>
                To log in, you must register via the email we sent you.{' '}
                <a href="mailto:support@kentik.com">Contact our support team.</a>
              </Callout>
              <Box mt={2} textAlign="center">
                <Text fontWeight="bold">New User?</Text>{' '}
                <a href="https://www.kentik.com/go/get-started" className="link">
                  Sign up for free!
                </a>
              </Box>
            </Fragment>
          )}
          {subtenancy && (
            <Fragment>
              <Callout title="Trouble Logging In?" pl={2}>
                {subtenancy.config.supportURL && (
                  <div className="pt-text-muted">
                    <a href={subtenancy.config.supportURL}>Visit our support page.</a>
                  </div>
                )}
                <div className="pt-text-muted">
                  <a href={`mailto:${subtenancy.config.supportEmail || 'support@kentik.com'}`}>
                    Contact our support team.
                  </a>
                </div>
              </Callout>

              {!subtenancy.permissions.advancedMode && (
                <Box mt={1}>
                  <PoweredByKentik blackText />
                </Box>
              )}
            </Fragment>
          )}
        </Card>
      </>
    );
  }
}
